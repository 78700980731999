import './styles/index.css';
import 'air-datepicker/air-datepicker.css';

document.addEventListener('DOMContentLoaded', async () => {
    const vh_burger = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh_burger}px`);

    const {BurgerMenu} = await import('./scripts/classes/BurgerMenu');
    new BurgerMenu();

    const {Slider} = await import('./scripts/classes/Slider');
    new Slider();

    if (document.querySelector('[data-entity=contacts]')) {
        const {ContactMap} = await import('./scripts/components/contact-map');
        new ContactMap();
    }

    if (document.querySelector('[data-entity=archive]')) {
        const {Calendar} = await import('./scripts/classes/Calendar.js');
        new Calendar();
    }

    if (document.querySelector('[data-entity=search-page]')) {
        const {Calendar} = await import('./scripts/classes/Calendar.js');
        new Calendar(true);
    }
});
